import { storageFactory } from "storage-factory";

export const local = storageFactory(() => localStorage);
export const session = storageFactory(() => sessionStorage);

export const setSession = (authResult) => {
    if(authResult?.authResult?.id){
        session.setItem('userInfo', JSON.stringify(authResult.authResult));
        /*session.setItem('username', authResult.name);
        session.setItem('user_email', authResult.email);
        session.setItem('user_reste', authResult.reste);
        session.setItem('user_consommer', authResult.consommer);*/


//                this.props.history.push("/test");
       
        
    }else{
             // Set the time that the access token will expire at
            let expiresAt = JSON.stringify((new Date(authResult.expires_at)* 1000) + new Date().getTime());
            session.setItem('access_token', authResult.access_token);
            session.setItem('token_type', authResult.token_type);
            session.setItem('expires_at', expiresAt);
    }
}
export const setLogout = () => {
    session.setItem('userInfo', JSON.stringify("{}"));
    session.removeItem('userInfo');
    session.removeItem('access_token');
    session.removeItem('token_type');
    session.removeItem('expires_at');
    session.clear();
}
export const getSession =() => JSON.parse(session.getItem("userInfo"));
