import axios from 'axios';
import {getCategoriesURL,getFeaturesURL,getItemsProductByIDURL,deleteFromCartURL,validateCartURL,orderNowURL,getOrdersURL,getOrderURL,likeProductURL,cancelOrderURL,getProductByIDURL,getCartByIDURL,getOrdersHistoryURL, addCart} from '../../utils/APIUrls';
import * as https from 'https';
const API_URL = process.env.PUBLIC_URL;

// API to get products from mock server
export const getProducts = function () {
    return axios.get( API_URL + '/mock-server/products.json' )
        .then( function ( response ) {
            return response.data;
        } )
        .catch( function ( error ) {
            // handle error
            console.log( error );
        } );
}

export const getCategories = function() {
  const httpsAgent = new https.Agent({ rejectUnauthorized: false });
    axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`}
    return axios
    .get(`${getCategoriesURL}`,httpsAgent)
    .then(function (response){
      //console.log('cats api:'+JSON.stringify(response.data));
      return response.data;}
    )
    .catch(err => console.log(err));
}

export const getAllBrands = function() {
  axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`}
  return axios
  .get(`${getCategoriesURL}`)
  .then(response =>
    response.data
  )
  .catch(err => console.log(err));
}

export const getFeatures = function(
    idSCat,
    idStore,
    idCat) {
        axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`}
        var url=getFeaturesURL +
        "?SubCategoryId=" +
        idSCat +
        "&StoreId=" +
        idStore +
        "&CategoryId=" +
        idCat;
        return axios
    .get(`${url}`)
    .then(response =>
      response.data
    )
    .catch(err => console.log(err));
  
}

export const getItemsByID=function(idPrd) {
    axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`}
    var url=getItemsProductByIDURL+ "?ProductId=" + idPrd;
    return axios
    .get(`${url}`)
    .then(response =>
      response.data
    )
    .catch(err => console.log(err));
  
}

export const getCartByID=function(userId) {
  if (userId){
    axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`}
    var url=getCartByIDURL + userId;
    return axios
    .get(`${url}`)
    .then(response =>
      response.data
    )
    .catch(err => console.log(err));
    }
}

export const addToCart=function(userId,
  idPrditem,qty) {
     axios.defaults.headers.common = {'Accept': 'application/json',
     'content-type': 'application/json','Authorization': `Bearer ${localStorage.getItem('access_token')}`}
     var url=addCart + userId;
     let data = `[{"qty":${qty},"productItemId":${idPrditem}}]`
     return axios
 .post(`${url}`,data)
 .then(response =>
   response.data
 )
 .catch(err => console.log(err));
}
export const deleteFromCart=function(
     idPrd,  idCart) {
        axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`}
        var url=deleteFromCartURL + "?cartId=+" + idCart + "&productId=" + idPrd;
        return axios
    .delete(`${url}`)
    .then(response =>
      response.data
    )
    .catch(err => console.log(err));
}

export const validateCart=function(address, paymentMeans,cartId) {
    axios.defaults.headers.common = {
      'Accept': 'application/json',
      'content-type': 'application/json','Authorization': `Bearer ${localStorage.getItem('access_token')}`}
    var url=validateCartURL;
    var data={
        'cartId': cartId,
        'paymentMethode': paymentMeans,
        'clientAddressId': address,
      };
    return axios
    .post(`${url}`,data)
    .then((response) => true
    )
    .catch(err => console.log(err));
  
}

export const orderNow=function(address, paymentMeans,
    newPrdItems) {
        axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`}
    var url=orderNowURL;
    var data={
        'orderLines': newPrdItems,
        'paymentMethode': paymentMeans,
        'clientAddressId': address.id,
      };
      return axios
    .post(`${url}`,data)
    .then(response =>
      response.data
    )
    .catch(err => console.log(err));
  
}

export const getOrdersByUserID=function(userId) {
    axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`}
    var url=getOrdersURL+userId;
    return axios
    .get(`${url}`)
    .then(response =>
      response.data
    )
    .catch(err => console.log(err));
}

export const getOrdersHistoryByUserID=function(userId,skipCount)  {
    axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`}
    var url=getOrdersHistoryURL +userId +"?SkipCount=" +skipCount;
    return axios
    .get(`${url}`)
    .then(response =>
      response.data
    )
    .catch(err => console.log(err));
  
}

export const getOrderByID=function(id) {
    axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`}
    var url=getOrderURL + id + "/client";
    return axios
    .get(`${url}`)
    .then(response =>
      response.data
    )
    .catch(err => console.log(err));
}
export const likeProduct=function(productID,userId) {
    axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`}
    var url=likeProductURL;
    var data={
        "id": productID,
        "clientUserId": userId,
      };
      return axios
    .post(`${url}`,data)
    .then(response =>
      response.data
    )
    .catch(err => console.log(err));
  
}


export const cancelOrderByClient=function(id)  {
    axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`}
    var url=cancelOrderURL;
    var data={
        'orderId': id,
      };
      return axios
    .post(`${url}`,data)
    .then(response =>
      response.data
    )
    .catch(err => console.log(err));

  
}

export const getProductByID=function( prdID,userId=null)  {
    axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`}
    var url=getProductByIDURL +
    "productLess?Id=" +
    prdID +(userId!==null?
    "&ClientUserId=" +
    userId:'');
    return axios
    .get(`${url}`)
    .then(response =>
      response.data
    )
    .catch(err => console.log(err));
}
