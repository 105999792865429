import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'react-app-polyfill/ie11';

import { PersistGate } from 'redux-persist/integration/react';

// import store
import store, { persistor } from './store';

// import action
import * as appService from './services/actions';
import * as homeService from './services/actions/home';
import * as accountService from './services/actions/account';

// import routes
import AppRoute from './routes';

// import Utils
import { initFunctions } from './utils';

import LoadingOverlay from './components/features/loading-overlay';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from "firebase/performance";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
// optional cofiguration
const options = {
    // you can also just use 'bottom center'
    position: positions.TOP_CENTER,
    timeout: 5000,
    offset: '0px',
    // you can also just use 'scale'
    transition: transitions.SCALE, //SCALE OR FADE
    
  }
  
/*
  const Root = () => (
    <AlertProvider template={AlertTemplate} {...options}>
      <App />
    </AlertProvider>
  )
ReactDOM.render(<Root />, document.getElementById('root'));
*/
export function Root() {
  const app=initializeApp({
    apiKey: "AIzaSyD42SMhLHHqv9WHcnQn50mfP0FJdffS_tQ",
    authDomain: "baazar-795b3.firebaseapp.com",
    projectId: "baazar-795b3",
    storageBucket: "baazar-795b3.appspot.com",
    messagingSenderId: "805841029366",
    appId: "1:805841029366:web:5cfbf7a118abb5eb9584d7",
    measurementId: "G-ERZT1X4SP8"
 })
 
 const analytics =getAnalytics(app);
 const perf = getPerformance(app);
 
 ReactGA.initialize('UA-253758248-01', { standardImplementation: true });
 const advancedMatching = { /*em: 'some@email.com'*/country:"dz" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init('1092589325420912', advancedMatching, options);

  initFunctions();
  //init apis store
  store.dispatch(appService.getAllCategories());
  store.dispatch(accountService.getUser());
  store.dispatch(homeService.getBazar())
  store.dispatch( homeService.getPromos() );
  //store.dispatch(appService.getAllBrands());
  
  useEffect( () => {
      /*if ( store.getState().modal.current !== 14 ) {
          store.dispatch( appService.refreshStore( 14 ) );
      }*/
  }, [] )

  return (
      <Provider store={ store } >
          <PersistGate persistor={ persistor } loading={ <LoadingOverlay /> }>
              <BrowserRouter basename={ '/' } >
                  <AppRoute />
              </BrowserRouter>
          </PersistGate>
      </Provider>
  );
}

//old for react 16
ReactDOM.render( <Root />, document.getElementById( 'root' ) );

//const container = document.getElementById("root");
//const root = ReactDOM.createRoot(container);
//root.render(<Root />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
//serviceWorker.register();
