export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const GET_USER = 'GET_USER';

export const SEARCH_PRODUCT = 'SEARCH_PRODUCT';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_STORE='FETCH_STORE';
export const LOADING = 'LOADING';
export const FETCH_RELATED_PRODUCTS='FETCH_RELATED_PRODUCTS';
export const FETCH_RELATED_STORES='FETCH_RELATED_STORES';
export const FETCH_PRODUCTS_STORES_HOME='FETCH_PRODUCTS_STORES_HOME';
export const FETCH_LIBRARY='FETCH_LIBRARY'
export const FETCH_CATEGORIES='FETCH_CATEGORIES'
export const FETCH_CATEGORIE_PRODUCTS='FETCH_CATEGORIE_PRODUCTS'
export const FETCH_SUBCATEGORIE_PRODUCTS='FETCH_SUBCATEGORIE_PRODUCTS'
export const FETCH_SALES_POINTS='FETCH_SALES_POINTS'
export const FETCH_BANNERS='FETCH_BANNERS' 
export const FETCH_BAZAR = 'FETCH_BAZAR'
export const FETCH_PROMOS = 'FETCH_PROMOS'
export const FETCH_NEWTIES = 'FETCH_NEWTIES'
export const FETCH_POPULARS = 'FETCH_POPULARS'
export const FETCH_BESTSELLER = 'FETCH_BESTSELLER'
export const FETCH_SUBCATEGORIES="FETCH_SUBCATEGORIES"
export const FETCH_SECTIONS="FETCH_SECTIONS"
export const FETCH_SECTIONSDATA="FETCH_SECTIONSDATA"
export const FETCH_BRANDS="FETCH_BRANDS"
// Get Products
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';

// Refresh Store
export const REFRESH_STORE = 'REFRESH_STORE';

//Quick     
export const SHOW_QUICKVIEW = 'SHOW_QUICKVIEW';
export const SHOW_DETAILVIEW = 'SHOW_DETAILVIEW';
export const CLOSE_QUICKVIEW = 'CLOSE_QUICKVIEW';

// Cart
export const EMPTY_CART = "EMPTY_CART";
export const SYNC_CART = "SYNC_CART";
export const GET_CART = 'GET_CART';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CHANGE_QTY = 'CHANGE_QTY';
export const CHANGE_SHIPPING = 'CHANGE_SHIPPING';

// WishList
export const TOGGLE_WISHLIST = 'TOGGLE_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';

// Compare
export const ADD_TO_COMPARE = 'ADD_TO_COMPARE';
export const REMOVE_FROM_COMPARE = 'REMOVE_FROM_COMPARE';
export const RESET_COMPARE = 'RESET_COMPARE';

//Product Filters
export const SORT_BY = 'SORT_BY';
export const CATEGORY_FILTER = 'CATEGORY_FILTER';
export const RESET_FILTER = 'RESET_FILTER';
export const SIZE_FILTER = 'SIZE_FILTER';
export const COLOR_FILTER = 'COLOR_FILTER';
export const BRAND_FILTER = 'BRAND_FILTER';
export const PRICE_FILTER = 'PRICE_FILTER';
export const RATING_FILTER = 'RATING_FILTER';

//Newsletter
export const HIDE_NEWSLETTER_MODAL = 'HIDE_NEWSLETTER_MODAL';

// Modal
export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const REMOVE_NEWSLETTER = 'REMOVE_NESLETTER';