import axios from 'axios';
import axiosRetry from 'axios-retry';
import * as api from '../../utils/APIUrls';
import https from 'https';

export const getHomeSectionsHeader=function(
     skipCount = 0,userId=null)  {
        axios.defaults.headers.common = {'Accept': 'application/json',
        'content-type': 'application/json','Authorization': `Bearer ${localStorage.getItem('access_token')}`}

        var url=api.allSections +"&SkipCount=" +skipCount +(userId!==null?"&clientUserId=" +userId:'');
        return axios
        .get(`${url}`,{
            crossdomain: true
          })
        .then(response =>
          response.data
        )
        .catch(err => console.log(err));
}

export const getHomeSectionsContent=function(
    userId=null,
   skipCount=0,
   PageShow=1
)  {
    axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`,'Accept': 'application/json',
    'content-type': 'application/json',}
        var url=api.getHomeSections +"?page=" +PageShow +"&SkipCount=" +skipCount + (userId!==null?"&clientUserId=" +userId:'');
        return axios
        .get(`${url}`)
        .then(response =>
          response.data
        )
        .catch(err => console.log(err));
}

export const getTopSales=function({ skipCount = 0,userId=null})  {
    axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`,'Accept': 'application/json',
    'content-type': 'application/json',}
        var url=api.getTopSales +"?Input.skipCount=" +skipCount + (userId!==null?"&clientUserId=" +userId:'');
        return axios
        .get(`${url}`)
        .then(response =>
          response.data
        )
        .catch(err => console.log(err));
}

export const getProductByBrand=function( id, 
    { skipCount = 0,userId=null})  {
        axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`,'Accept': 'application/json',
        'content-type': 'application/json',}
        var url=api.getProductsByBrand +        id +        "?Input.skipCount=" +        skipCount + (userId!==null?"&clientUserId=" +userId:'');
        return axios
        .get(`${url}`)
        .then(response =>
          response.data
        )
        .catch(err => console.log(err));
}

export const getBazar=function( skipCount=0,userId=null)  {
    axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`,'Accept': 'application/json',
    'content-type': 'application/json',}
        var url=api.getBazar +
        "?Input.skipCount=" +
        skipCount+ (userId!==null?"&clientUserId=" +userId:'');
        return axios
        .get(`${url}`)
        .then(response =>
          response.data
        )
        .catch(err => console.log(err));
}

export const getPromos=function(skipCount = 0,userId=null)  {
    axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`,'Accept': 'application/json',
    'content-type': 'application/json',}
    var url=api.getPromos +
    "?Input.SkipCount=" +
    skipCount+ (userId!==null?"&clientUserId=" +userId:'');
    return axios
    .get(`${url}`)
    .then(response =>
      response.data
    )
    .catch(err => console.log(err));
}

export const getMoreProducts=function(
   id,
   skipCount,userId,maxResultCount=20
)  {
  axiosRetry(axios, { retries: 5,retryDelay: axiosRetry.exponentialDelay});
    axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`,'Accept': 'application/json',
    'content-type': 'application/json',}
    var url=api.productsBySectionId +"?productSectionId=" +id +"&SkipCount=" +skipCount+"&MaxResultCount="+maxResultCount+ (userId!==undefined?"&clientUserId=" +userId:'');
    return axios
    .get(`${url}`)
    .then(response =>
      response.data
    )
    .catch(err => console.log(err));
}

export const getMoreStores=function(
     id,  skipCount) {
        axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`,'Accept': 'application/json',
        'content-type': 'application/json',}
        var url=api.StoreSection +"?storeSectionId=" +id +"&SkipCount=" +skipCount;
        return axios
        .get(`${url}`)
        .then(response =>
          response.data
        )
        .catch(err => console.log(err));
}

export const getMoreBrands=function(
     id,  skipCount)  {
        axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`,'Accept': 'application/json',
        'content-type': 'application/json',}
        var url=api.brandSection +
        "?brandSectionId=" +
        id +
        "&SkipCount=" +
        skipCount;
        return axios
        .get(`${url}`)
        .then(response =>
          response.data
        )
        .catch(err => console.log(err));
}

export const search=function(query,skipCount = 0,userId=null)  {
  axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`,'Accept': 'application/json',
  'content-type': 'application/json'}
  var url=api.search +"?input="+  query+
  "&SkipCount=" +
  skipCount+ (userId!==null?"&clientUserId=" +userId:'');
  return axios
  .get(`${url}`)
  .then(response =>
    response.data
  )
  .catch(err => console.log(err));
}