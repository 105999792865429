import {
    SEARCH_PRODUCT,
    FETCH_PRODUCTS,
    FETCH_PRODUCTS_STORES_HOME,
    FETCH_PRODUCT,
    FETCH_STORE,
    FETCH_RELATED_PRODUCTS,
    FETCH_RELATED_STORES,
    LOADING,
    FETCH_LIBRARY,
    FETCH_SALES_POINTS,
    FETCH_BANNERS,
    FETCH_SUBCATEGORIES,
    FETCH_SECTIONS,
    FETCH_BRANDS
  } from '../actions/action-types';
  
  const initialState = {
    text: '',
    searchResult: [],
    relatedProducts:[],
    relatedStores:[],
    loading: false,
    product: [],
    tvShow:[],
    genresNames:[],
    pointsDeVente:[],
    banners:[],
    storeData:{}
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case SEARCH_PRODUCT:
        return {
          ...state,
          text: action.payload,
          loading: false
        };
      case FETCH_PRODUCTS:
        return {
          ...state,
          searchResult: action.payload,
          loading: false
        };
        case FETCH_PRODUCTS_STORES_HOME:
          return {
            ...state,
            movies: action.payload,
            loading: false
          };
        case FETCH_SECTIONS:
            return {
              ...state,
              movies: action.payload,
              loading: false
            };
     
      case FETCH_PRODUCT:
        return {
          ...state,
          product: action.payload,
         
        };
      case FETCH_STORE:
          return {
            ...state,
            storeData: action.payload,
            
          };
      case FETCH_RELATED_PRODUCTS:
       
          return {
            ...state,
            relatedProducts: action.payload,
           
            
            loading: false
          };
          case FETCH_RELATED_STORES:
       
            return {
              ...state,
              relatedStores: action.payload,
              
              loading: false
            };
      case FETCH_LIBRARY:
       
            return {
              ...state,
              movies: action.payload,
              loading: false
            };
      case FETCH_SUBCATEGORIES:
       
              return {
                ...state,
                movies: action.payload,
                loading: false
              };
      case FETCH_BRANDS:
          return {
            ...state,
            genresNames: action.payload,
            loading: false
          };
      case FETCH_SALES_POINTS:
          return {
            ...state,
            pointsDeVente: action.payload,
            loading: false
          };
      case FETCH_BANNERS:
              return {
                ...state,
                banners: action.payload,
                
              };
      
      case LOADING:
        return {
          ...state,
          loading: true
        };
      default:
        return state;
    }
  }